import {warn} from '@verily-src/phaf-runtime-helpers';
import {config} from '@verily-src/phaf-runtime-helpers/src/mfe_helpers/configurationWrapper';
import {ANALYTICS_COOKIE_GROUP, apiKeyMap} from '../constants';
import {
  InitUserAnalyticsOpts,
  VisitorMetaRecord,
  VisitorMetaValue,
} from '../types';
import {PendoValidator} from '../validation/PendoValidator';
import {getVisitorId} from './anonymous/getVisitorId';
import {
  loadPendoAgent,
  pendoIdentify,
  pendoInitialize,
  pendoTeardown,
  pendoTrack,
} from './pendoLoader';

/**
 * This class is responsible for initializing Pendo analytics. There are two distinct modes of operation:
 * 1. Analytics is initialized with enabledIdentifiableAnalytics = false
 *    - pendoInitialize is called with a random visitor id
 * 2. Analytics is initialized with enabledIdentifiableAnalytics = true
 *   - OneTrustEventHandler is called
 *    - If user has granted consent, pendoInitialize is called with provided visitor id
 *      - If visitor id is not provided, pendo will use a random ID until an id is set. The events will be collated once ID is set.
 *    - If user has not granted consent, pendo is not started.
 *    2a. User grants consent
 *    - OneTrustEventHandler is called
 *      - pendoInitialize is called with provided visitor id
 *   2b. User revokes consent
 *   - OneTrustEventHandler is called
 *    - pendoTeardown is called
 */

class Analytics {
  private validator = PendoValidator;
  // Analytics can be initialized only once.
  private isInitialized = false;
  // Analytics can be running only if it is initialized.
  // It can start and restart based on OneTrust changes.
  private isRunning = false;
  // We initialize with the feature flag value. This is set during initialization
  // but is needed to block out of band operations. i.e. setVisitorMetadata while FF is set to false.
  private enabledIdentifiableAnalytics: boolean = config.getBoolean(
    'FEATURE_IDENTIFIABLE_ANALYTICS_ENABLED'
  );
  // These properties can be set out of band but are ONLY used if identifiable analytics is enabled.
  private visitor: VisitorMetaRecord | null = null;
  private handleOneTrustChange!: () => void;

  /**
   * @deprecated
   * Returns the API key based on the current URL path.
   */
  private get apiKeyByCurrentPath() {
    const url = window.location.pathname;
    const appSuite = url.split('/')[1].toLowerCase();
    return apiKeyMap.get(appSuite);
  }

  /**
   * Tears down pendo. Resets the isRunning flag to false.
   */
  private teardown() {
    this.isRunning = false;
    pendoTeardown();
  }

  /**
   * Checks if the user has granted consent for the analytics cookie group.
   */
  private isOneTrustAnalyticsConsentGranted = (): boolean => {
    const {OnetrustActiveGroups} = window;

    if (OnetrustActiveGroups) {
      return OnetrustActiveGroups.includes(ANALYTICS_COOKIE_GROUP);
    }
    return false;
  };

  /**
   * Event handler for OneTrust event
   * @param {Analytics} self - it is needed for passing this (Analytics) object
   * @param {VisitorMetaRecord} visitorMeta - visitor meta data
   */
  private OneTrustEventHandler(
    self: Analytics,
    visitorMeta?: VisitorMetaRecord
  ): void {
    // If analytics is not running and user has granted consent, start it.
    if (self.isOneTrustAnalyticsConsentGranted() && !self.isRunning) {
      self.startPendoWithVisitorMeta(visitorMeta);
    } else if (!self.isOneTrustAnalyticsConsentGranted() && self.isRunning) {
      // If analytics is running and user has not granted consent, tear it down.
      self.teardown();
    }
  }

  /**
   * Handles any change in OneTrust cookie group, including the initial state.
   * Adds OneTrust event listener to window object.
   */
  private handleOneTrustCookies(visitorMeta?: VisitorMetaRecord) {
    // This is needed for correctly passing this keyword referring to analytics object
    // Otherwise this keyword in the handler will refer to handler function
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;
    this.handleOneTrustChange = this.OneTrustEventHandler.bind(
      null,
      self,
      visitorMeta
    );
    window.addEventListener('OneTrustGroupsUpdated', this.handleOneTrustChange);

    // Call to handle initial state. This function is idempotent, the result will only change if the consent changes.
    this.handleOneTrustChange();
  }

  private validateVisitorMeta(
    visitorMeta: VisitorMetaRecord | undefined
  ): boolean {
    if (!this.validator.isValidMetadata(visitorMeta)) {
      warn(
        'Visitor metadata name should start with a letter or underscore and should include only letters, numbers and underscores.'
      );
      return false;
    }
    return true;
  }

  /**
   * Checks for any invalid visitor metadata, if no meta is provided uses the visitor metadata set on instance.
   * @param visitorMeta - metadata record, containing id and optional metadata
   * @returns input if valid, null if invalid.
   */
  private isValidVisitor(
    visitorMeta?: VisitorMetaRecord
  ): VisitorMetaRecord | null {
    let visitorMetaToUse: VisitorMetaRecord | undefined = visitorMeta;
    let visitorIdToUse: VisitorMetaValue | null = null;

    // If visitorMeta is not provided, use the visitor metadata set on instance (via out of band setVisitorMetadata()).
    if (!visitorMetaToUse && this.visitor) {
      visitorMetaToUse = this.visitor;
    }

    // No data set. Early return.
    if (!visitorMetaToUse) {
      return null;
    }

    if (!this.validateVisitorMeta(visitorMetaToUse)) {
      warn('Visitor metadata is invalid. Pendo will not use visitor metadata.');
      return null;
    }

    if (visitorMetaToUse && visitorMetaToUse['id']) {
      visitorIdToUse = visitorMetaToUse?.id;
      if (!this.validator.isValidId(visitorIdToUse.toString())) {
        warn(
          'Visitor id should be a string and should include only letters, numbers and underscores.'
        );
        return null;
      }
    }

    if (!visitorIdToUse) {
      warn(
        'No visitor id provided. Pendo will generate a random id and events will be collated once ID had been provided.'
      );
    }

    return visitorMetaToUse;
  }

  /**
   * Initializes pendo with visitor metadata after validating it, will combine with previously set metadata.
   * @param visitorMeta - visitor metadata
   */
  private startPendoWithVisitorMeta(visitorMeta?: VisitorMetaRecord) {
    const visitorMetaToUse = this.isValidVisitor(visitorMeta);

    pendoInitialize(visitorMetaToUse);
    this.isRunning = true;
  }

  /**
   * Initializes pendo based on enabledIdentifiableAnalytics.
   * This will not do anything if it called more than once. Analytics can restart itself if OneTrust cookie group changes.
   * @param {boolean} enabledIdentifiableAnalytics - set by the caller of the analytics MFE
   * @param {InitUserAnalyticsOpts} opts - Pendo metadata
   */
  initializeUserAnalytics = (
    enabledIdentifiableAnalytics: boolean = false,
    opts?: InitUserAnalyticsOpts
  ) => {
    if (this.isInitialized) {
      warn('Pendo analytics is already initialized');
      return;
    }
    // This is blocked by a feature flag so while teams can opt it in, it is not usable until it has been launched.
    this.enabledIdentifiableAnalytics =
      this.enabledIdentifiableAnalytics && enabledIdentifiableAnalytics;

    // Default to provided apiKey, but fallback to the one derived from the URL.
    const apiKey = opts?.apiKey || this.apiKeyByCurrentPath;
    if (!apiKey) {
      warn('No API key provided for Pendo initialization');
      return;
    }

    // This should only be called once.
    loadPendoAgent(apiKey);

    // Run in identifiable analytics mode if enabled, otherwise anonymous mode.
    if (this.enabledIdentifiableAnalytics) {
      this.handleOneTrustCookies(opts?.visitorMeta);
    } else {
      pendoInitialize({id: getVisitorId(), anonymous: true});
      this.isRunning = true;
    }
    this.isInitialized = true;
  };

  /**
   * Sets visitor metadata for pendo events.
   * Requires identifiable analytics to be enabled.
   * @param visitor - visitor metadata
   */
  setVisitorMetadata = (visitor: VisitorMetaRecord) => {
    if (this.enabledIdentifiableAnalytics) {
      if (this.isRunning) {
        const visitorMeta = this.isValidVisitor(visitor);
        pendoIdentify(visitorMeta);
      } else {
        // Set visitor locally so it can be used when pendo is initialized.
        this.visitor = visitor;
      }
    }
  };

  /**
   * Sets visitor id for pendo events while keeping any other metadata values that were previously set.
   */
  setVisitor = (visitorId: string) => {
    const visitor = {id: visitorId, ...this.visitor};

    this.setVisitorMetadata(visitor);
  };

  /**
   * Track a pendo event.
   */
  track = (name: string, metadata?: Record<string, string>) => {
    if (this.isRunning) {
      pendoTrack(name, metadata);
    } else {
      warn('Pendo is not running. Cannot track event.');
    }
  };

  /**
   * Removes OneTrust event listener from window object.
   * This function is ONLY used for unit tests.
   */
  private removeEventListener() {
    window.removeEventListener(
      'OneTrustGroupsUpdated',
      this.handleOneTrustChange
    );
  }
}

export {Analytics, ANALYTICS_COOKIE_GROUP};
